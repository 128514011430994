<template>
  <div
    class="flex col-12 justify-content-between p-3 border-round"
    style="background: #f8f9fa"
  >
    <div class="p-2">Manage team member/s</div>
    <div class="p-2">
      <i class="la la-times cursor-pointer" @click="clearPopupDialog()"></i>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>